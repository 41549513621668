import { NumberList as NumberListComponent } from "../number-list";
import type { NumberProps } from "../number-list";
import { asText } from "~/utils/sanity-helpers";
import type { NumberList as NumberListType } from "~/types/sanity-schema";

export function NumberList({
	align,
	title,
	subtitle,
	numbers,
}: NumberListType) {
	const numberItems = numbers
		? numbers.map<NumberProps>((item) => ({
				number: item?.number,
				description: item?.description,
			}))
		: [];

	return (
		<NumberListComponent
			align={align}
			title={asText(title)}
			subtitle={subtitle}
			numberItems={numberItems}
		/>
	);
}
