import React from "react";
import clsx from "clsx";
import { H2, Paragraph } from "~/components/ui/typography";
import { BlockContent } from "~/components/block-content";
import type { SimplePortableText } from "~/types/sanity-schema";
import type { Cta, SocialLinks as SocialLinksType } from "~/types";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import { ArrowLink } from "./ui/arrow-button";
import { SocialLinks } from "./social-links";
import { ConditionalLink } from "~/components/ui/link";
import { Icon } from "~/components/ui/icons";
import { Modal } from "~/components/ui/modal";
import { spTrackWebInteraction } from "~/utils/tracking";

export type Avatar = {
	variant?: "vertical" | "horizontal" | "dense";
	image: ImageProps;
	name: string;
	jobTitle?: string;
	bio?: SimplePortableText;
	socialLink?: SocialLinksType;
	showProfilePage?: boolean;
	isShowBioModal?: boolean;
	hideSoMe?: boolean;
	cta?: Cta;
};

export function AvatarItem({
	variant,
	image,
	name,
	jobTitle,
	bio,
	socialLink,
	showProfilePage = false,
	hideSoMe = false,
	cta,
	isShowBioModal,
}: Avatar) {
	const hasLink = Boolean(showProfilePage && cta?.url);

	const [showBioModal, setShowBioModal] = React.useState(false);

	const closeBioModal = (name: string) => {
		setShowBioModal(false);
		spTrackWebInteraction({
			object: "bio modal",
			action: "close",
			value: `${name}`,
		});
	};

	const openBioModal = (name: string) => {
		setShowBioModal(true);
		spTrackWebInteraction({
			object: "bio modal",
			action: "open",
			value: `${name}`,
		});
	};

	return (
		<>
			<figure
				className={clsx("flex", {
					"max-w-[220px] flex-col gap-5 sm:h-full": variant === "vertical",
					"max-w-[670px] flex-col gap-6 sm:flex-row": variant === "horizontal",
					"max-w-[350px] flex-row items-center gap-5": variant === "dense",
					"group relative": hasLink,
				})}
			>
				{isShowBioModal && bio ? (
					<div
						className="group/image relative cursor-pointer"
						onClick={() => openBioModal(name)}
					>
						{variant !== "dense" ? (
							<button
								title="View bio"
								className="bg-primary border-button-secondary absolute right-3 top-3 flex h-[24px] w-[24px] items-center justify-center rounded-sm border group-hover/image:border-grey-80"
							>
								<Icon
									name="three-dots"
									width="24"
									height="24"
									color="current"
								/>
							</button>
						) : null}
						{image?.src ? (
							<Image
								loading="eager"
								className={clsx({
									"rounded-lg": variant !== "dense",
									"max-w-[152px] self-start": variant === "horizontal",
									"border-button-secondary max-w-[48px] self-start rounded-full border":
										variant === "dense",
								})}
								{...image}
							/>
						) : null}
					</div>
				) : (
					<>
						{image?.src ? (
							<Image
								loading="eager"
								className={clsx({
									"rounded-lg": variant !== "dense",
									"max-w-[152px] self-start": variant === "horizontal",
									"max-w-[48px] self-start rounded-full": variant === "dense",
								})}
								{...image}
							/>
						) : null}
					</>
				)}
				<figcaption className="sm:flex sm:flex-col sm:justify-between">
					<div>
						{name ? (
							<ConditionalLink
								condition={hasLink}
								to={cta?.url || ""}
								trackingPosition="avatar"
								className={clsx({
									"after:absolute after:inset-0 after:content-[''] group-focus-within:outline-none":
										hasLink,
								})}
							>
								<Paragraph
									as="span"
									size={variant === "dense" ? "body-small" : "body-default"}
									fontWeight={
										variant === "dense" ? "font-medium" : "font-semibold"
									}
								>
									{name}
								</Paragraph>
							</ConditionalLink>
						) : null}
						{variant !== "dense" ? (
							<>
								{jobTitle ? (
									<Paragraph size="body-small" color="secondary">
										{jobTitle}
									</Paragraph>
								) : null}
								{!isShowBioModal && bio ? (
									<div className="mt-5">
										<BlockContent
											value={bio}
											paragraphColor="primary"
											blockOverrides={{
												normal: ({ children }) => (
													<Paragraph size="body-small">{children}</Paragraph>
												),
											}}
										/>
									</div>
								) : null}
							</>
						) : null}
					</div>
					{variant !== "dense" ? (
						<>
							{!hideSoMe && socialLink ? (
								<div className="mt-3">
									<SocialLinks socialLink={socialLink} />
								</div>
							) : null}
							{showProfilePage && cta?.url ? (
								<ArrowLink
									className="mt-3"
									to={cta.url}
									trackingPosition="avatar"
								>
									{cta?.title}
								</ArrowLink>
							) : null}
						</>
					) : null}
				</figcaption>
			</figure>
			<Modal
				ariaLabel="Biography"
				isOpen={showBioModal}
				onClose={() => closeBioModal(name)}
			>
				<Modal.Header
					title={name}
					subtitle={<Paragraph color="secondary">{jobTitle}</Paragraph>}
				/>
				<Modal.Content className="flex flex-col gap-6 sm:flex-row">
					<div className="flex flex-col gap-3">
						{image?.src ? (
							<Image className="max-w-[220px] rounded-lg" {...image} />
						) : null}
						{!hideSoMe && socialLink ? (
							<SocialLinks socialLink={socialLink} />
						) : null}
					</div>
					<div>
						<BlockContent value={bio} paragraphColor="primary" />
					</div>
				</Modal.Content>
			</Modal>
		</>
	);
}

interface AvatarListProps {
	align?: "left" | "center";
	variant?: "default" | "withBio" | "withBioModal";
	tagline?: string;
	title?: string;
	subtitle?: SimplePortableText;
	avatarItems: Avatar[];
}

export function AvatarList({
	align = "center",
	variant = "default",
	tagline,
	title,
	subtitle,
	avatarItems,
}: AvatarListProps) {
	return (
		<>
			{title || subtitle ? (
				<div
					className={clsx("mb-8 max-w-content", {
						"mx-auto text-center": align === "center",
					})}
				>
					{tagline ? (
						<Paragraph size="overline" className="mb-5" color="tagline">
							{tagline}
						</Paragraph>
					) : null}
					{title ? <H2>{title}</H2> : null}
					{subtitle ? (
						<div className="mt-5">
							<BlockContent value={subtitle} />
						</div>
					) : null}
				</div>
			) : null}
			{avatarItems.length > 0 ? (
				<div
					className={clsx(
						"flex flex-col items-center gap-layout4 sm:flex-row sm:items-start",
						{
							"sm:justify-center": align === "center",
							"sm:grid sm:grid-cols-2 sm:justify-items-center md:grid-cols-3":
								variant !== "withBio" && avatarItems.length === 6,
							"sm:grid sm:grid-cols-2 sm:justify-items-center md:grid-cols-3 lg:grid-cols-4":
								variant !== "withBio" &&
								avatarItems.length >= 4 &&
								avatarItems.length !== 6,
							"sm:grid sm:grid-cols-1 lg:grid-cols-2":
								variant === "withBio" && avatarItems.length >= 2,
						}
					)}
				>
					{avatarItems.map((avatar, index) => (
						<AvatarItem
							key={index}
							variant={variant === "withBio" ? "horizontal" : "vertical"}
							image={avatar.image}
							name={avatar.name}
							jobTitle={avatar.jobTitle}
							bio={variant !== "default" ? avatar.bio : null}
							socialLink={avatar.socialLink}
							showProfilePage={avatar.showProfilePage}
							hideSoMe={avatar.hideSoMe}
							cta={avatar.cta}
							isShowBioModal={variant === "withBioModal"}
						/>
					))}
				</div>
			) : null}
		</>
	);
}
