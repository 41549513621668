import React from "react";
import clsx from "clsx";
import { Icon } from "~/components/ui/icons";

type SelectProps = JSX.IntrinsicElements["select"];
type SelectOptionProps = JSX.IntrinsicElements["option"];
type SelectOptionGroupProps = JSX.IntrinsicElements["optgroup"];

export function Option({ value, children, ...props }: SelectOptionProps) {
	return (
		<option value={value} {...props}>
			{children}
		</option>
	);
}

export function OptGroup(props: SelectOptionGroupProps) {
	return <optgroup {...props}>{props.children}</optgroup>;
}
// className="text-primary border-stroke bg-primary h-[42px] w-full appearance-none rounded border px-5 py-3 font-body outline-none"

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
	function Select(props, ref) {
		const classNames = clsx(
			"h-[42px] w-full appearance-none rounded border border-stroke bg-primary px-5 py-3 disabled:opacity-30 disabled:cursor-not-allowed focus-visible:outline-none focus-visible:border-grey-80",
			props.className
		);
		return (
			<div className="relative">
				<select {...props} className={classNames} ref={ref} />
				<Icon
					name="chevron-down"
					color="secondary"
					className="pointer-events-none absolute right-5 top-4 h-5 w-5"
				/>
			</div>
		);
	}
);
