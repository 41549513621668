import { Paragraph } from "~/components/ui/typography";
import { LogoItem } from "~/components/logo-list";
import type { Logo } from "~/components/logo-list";

interface Props {
	className?: string;
	title?: string;
	logoItems: Logo[];
}

export function Tools({ className, title, logoItems }: Props) {
	return (
		<>
			{logoItems.length > 0 ? (
				<div className={className}>
					{title ? (
						<Paragraph
							fontWeight="font-medium"
							className="text-center xl:text-left"
						>
							{title}
						</Paragraph>
					) : null}
					<div className="flex flex-wrap items-center justify-center gap-3 pt-5 md:justify-start">
						{logoItems.map((logo, index) => (
							<LogoItem key={index} logo={logo} isShowLogoCaption={true} />
						))}
					</div>
				</div>
			) : null}
		</>
	);
}
