import { ServiceDiagram as ServiceDiagramComponent } from "../service-diagram/service-diagram";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/utils/images";
import type { Step } from "../service-diagram/steps";
import type { ServiceDiagram as ServiceDiagramType } from "~/types/sanity-schema";
import type { Logo } from "../logo-list";
import { DEFAULT_LOGO_HEIGHT } from "~/utils/media-height";

export function ServiceDiagram({
	variant = "kafka",
	title,
	subtitle,
	tagline,
	steps,
	resources,
	toolsTitle,
	tools,
}: ServiceDiagramType) {
	const stepItems = steps
		? steps.map<Step>((item) => ({
				content: item?.content,
			}))
		: [];

	const toolItems = tools
		? tools.map<Logo>((item) => ({
				logoImage: getImageProps(
					getImageBuilder(item?.logo?.logoImage, {
						alt: getImageAltProp(item?.logo?.logoImage),
					})?.height(
						item?.logo?.logoHeight ? item.logo.logoHeight : DEFAULT_LOGO_HEIGHT
					)
				),
				logoHeight: item?.logo?.logoHeight,
				title: item?.logo?.title,
				ctaLink: item?.logo?.ctaLink,
			}))
		: [];

	return (
		<>
			<ServiceDiagramComponent
				variant={variant}
				tagline={tagline}
				title={title}
				subtitle={subtitle}
				steps={stepItems}
				resources={resources}
				toolsTitle={toolsTitle}
				tools={toolItems}
			/>
		</>
	);
}
