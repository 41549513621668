import {
	Marketecture as MarketectureComponent,
	Header,
} from "~/components/marketecture";
import type { Marketecture as MarketectureType } from "~/types/sanity-schema";
import type { AccordionItem } from "~/components/ui/accordion";
import { asText } from "~/utils/sanity-helpers";
import { BlockContent } from "~/components/block-content";

export function Marketecture({
	tagline,
	title,
	subtitle,
	items,
}: MarketectureType) {
	const accordionItems = items
		? items.map<AccordionItem>((item) => ({
				id: item.id,
				title: asText(item.title),
				panel: item.panelText ? (
					<BlockContent value={item.panelText} paragraphClassName="!text-sm" />
				) : null,
			}))
		: [];

	return (
		<>
			<Header tagline={tagline} title={title} subtitle={subtitle} />
			<MarketectureComponent items={accordionItems} />
		</>
	);
}
