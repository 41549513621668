import clsx from "clsx";
import { H2 } from "~/components/ui/typography";
import { BlockContent } from "~/components/block-content";
import type { SimplePortableText } from "~/types/sanity-schema";

export type NumberProps = {
	number?: string;
	description?: SimplePortableText;
	className?: string;
};

export function NumberItem({ number, description, className }: NumberProps) {
	return (
		<article className={className}>
			{number ? <H2 as="p">{number}</H2> : null}
			{description ? <BlockContent value={description} /> : null}
		</article>
	);
}

interface NumberListProps {
	align?: "center" | "left";
	title?: string;
	subtitle?: SimplePortableText;
	numberItems: NumberProps[];
}

export function NumberList({
	align = "left",
	title,
	subtitle,
	numberItems,
}: NumberListProps) {
	return (
		<>
			<div
				className={clsx("mb-layout3 max-w-content", {
					"mx-auto text-center": align === "center",
				})}
			>
				{title ? <H2>{title}</H2> : null}
				{subtitle ? (
					<div className="mt-5">
						<BlockContent value={subtitle} />
					</div>
				) : null}
			</div>
			{numberItems.length > 0 ? (
				<div className="flex flex-wrap gap-layout2 sm:gap-layout4">
					{numberItems.map((item, index) => (
						<NumberItem
							key={index}
							number={item.number}
							description={item.description}
							className={clsx("flex-1", {
								"text-center": align === "center",
							})}
						/>
					))}
				</div>
			) : null}
		</>
	);
}
