import React from "react";
import type {
	Agenda as AgendaType,
	EventSessionItem,
	AvatarList as AvatarListType,
} from "~/types/sanity-schema";
import Carousel from "../carousel/carousel";
import { Image } from "../ui/image";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/utils/images";
import { H2, Paragraph } from "../ui/typography";
import { asText } from "~/utils/sanity-helpers";
import { Tag } from "../ui/tag";
import { BlockContent } from "../block-content";
import type { Avatar } from "../avatar-list";
import { AvatarItem as AvatarItemComponent } from "../avatar-list";
import clsx from "clsx";
import { spTrackWebInteraction } from "~/utils/tracking";
import { Icon } from "~/components/ui/icons";

function AvatarList({ avatars }: AvatarListType) {
	const avatarItems = avatars
		? avatars.map<Avatar>((item) => ({
				image: getImageProps(
					getImageBuilder(item?.image, {
						alt: getImageAltProp(item?.image, {
							fallback: `${item?.name} portrait`,
						}),
					})
						?.width(220)
						.height(220)
				),
				name: asText(item?.name),
				jobTitle: item?.jobTitle,
				bio: item?.bio,
				socialLink: item?.socialLink,
			}))
		: [];

	return (
		<>
			{avatarItems.map((avatar, index) => (
				<AvatarItemComponent
					key={index}
					variant="dense"
					image={avatar.image}
					name={avatar.name}
					jobTitle={avatar.jobTitle}
					bio={avatar.bio}
					socialLink={avatar.socialLink}
					isShowBioModal={true}
				/>
			))}
		</>
	);
}

function EventSession({
	title,
	items,
}: {
	title: string;
	items: Array<EventSessionItem>;
}) {
	const [openItems, setOpenItems] = React.useState<{
		[key: number]: boolean;
	}>({});

	const toggleItem = (index: number, item: EventSessionItem) => {
		setOpenItems((prevOpenItems) => {
			const isOpen = !prevOpenItems[index];
			spTrackWebInteraction({
				object: "agenda detail",
				action: isOpen ? "open" : "close",
				value: item.title || "",
			});
			return {
				...prevOpenItems,
				[index]: isOpen,
			};
		});
	};

	return (
		<div className="relative">
			{title ? (
				<div className="flex items-center pb-6">
					<Paragraph size="overline" className="pr-5">
						{title}
					</Paragraph>
					<div className="flex-1 border-b border-grey-100" />
				</div>
			) : null}

			<ul className="bg-primary rounded-2xl p-7">
				{items.map((item, index) => (
					<li
						key={index}
						className="border-stroke border-b py-6 first:pt-0 last:border-b-0 last:pb-0"
					>
						<div className="mb-6 flex flex-col gap-3 sm:flex-row">
							{item.time ? (
								<Tag bgColor="bg-blue-0" textColor="blue">
									<span suppressHydrationWarning>{item.time}</span>
								</Tag>
							) : null}
							{item.category ? <Tag>{item.category}</Tag> : null}
						</div>
						{item.description ? (
							<>
								<button
									onClick={() => toggleItem(index, item)}
									className="mb-3"
								>
									<div className="flex gap-3 text-left">
										{openItems[index] ? (
											<Icon
												name="chevron-up"
												color="current"
												className="mt-3 h-[12px] w-[12px] flex-shrink-0"
											/>
										) : (
											<Icon
												name="chevron-down"
												color="current"
												className="mt-3 h-[12px] w-[12px] flex-shrink-0"
											/>
										)}
										<Paragraph fontWeight="font-semibold">
											{item.title}
										</Paragraph>
									</div>
								</button>
								{openItems[index] ? (
									<BlockContent value={item.description} />
								) : null}
							</>
						) : (
							<Paragraph fontWeight="font-semibold">{item.title}</Paragraph>
						)}
						{item.avatars ? (
							<div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6 pt-4">
								<AvatarList avatars={item.avatars} _type="avatarList" />
							</div>
						) : null}
					</li>
				))}
			</ul>
		</div>
	);
}

export function Agenda({ carouselImages = [], eventSessions }: AgendaType) {
	const hasCarouselImage = carouselImages != null && carouselImages.length > 0;

	const firstImage = hasCarouselImage ? carouselImages[0] : null;

	return (
		<>
			{firstImage?.title || firstImage?.tagLine ? (
				<div className="mx-auto mb-8 max-w-content text-center lg:hidden">
					{firstImage.tagLine ? (
						<Paragraph size="overline" className="mb-5" color="tagline">
							{firstImage.tagLine}
						</Paragraph>
					) : null}
					{firstImage.title ? <H2>{firstImage.title}</H2> : null}
				</div>
			) : null}
			<div
				className={clsx("grid grid-cols-1 gap-9", {
					"lg:grid-cols-2": hasCarouselImage,
				})}
			>
				{hasCarouselImage ? (
					<div
						className="sticky self-start"
						style={{
							top: `calc(var(--nav-height) + 48px)`,
						}}
					>
						<div className="hidden lg:block">
							<Carousel
								autoplay={true}
								autoplaySpeed={5000}
								fade={true}
								arrows={false}
								dots={false}
							>
								{carouselImages?.map((carouselImage, index) => {
									const { title, tagLine } = carouselImage;
									return (
										<figure
											className="relative overflow-hidden rounded-2xl bg-black"
											key={index}
										>
											<figcaption className="dark absolute left-8 top-7 [&>*]:drop-shadow-md">
												{tagLine ? (
													<Paragraph
														size="overline"
														className="mb-5 block"
														color="tagline"
														as="span"
													>
														{tagLine}
													</Paragraph>
												) : null}
												{title ? <H2 as="span">{title}</H2> : null}
											</figcaption>

											<Image
												className="aspect-[3/4] w-full object-cover"
												{...getImageProps(
													getImageBuilder(carouselImage.image, {
														alt: getImageAltProp(carouselImage.image),
													}),
													{
														widths: [600, 800, 1000, 1300],
														sizes: ["(min-width:1024px) 50vw", "100vw"],
													}
												)}
											/>
										</figure>
									);
								})}
							</Carousel>
						</div>
					</div>
				) : null}
				<ul>
					{eventSessions?.map(({ title, items }, index) => (
						<li key={index} className="mb-layout5 last:mb-0">
							<EventSession title={asText(title)} items={items ?? []} />
						</li>
					))}
				</ul>
			</div>
		</>
	);
}
