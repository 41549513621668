import clsx from "clsx";
import React from "react";
import { H2, Paragraph } from "~/components/ui/typography";
import type { Logo } from "~/components/logo-list";
import { BlockContent } from "~/components/block-content";
import type { Step } from "./steps";
import {
	Steps,
	Tools,
	KafkaSvg,
	FlinkSvg,
	PgSvg,
	OpensearchSvg,
	ClickhouseSvg,
	GrafanaSvg,
	InfluxdbSvg,
	MysqlSvg,
	KafkaConnectSvg,
	M3Svg,
	KafkaEcosystemSvg,
} from ".";
import { useMobile } from "~/utils/hooks";
import type { SimplePortableText } from "~/types/sanity-schema";

interface ServiceDiagramProps {
	variant:
		| "flink"
		| "kafka"
		| "opensearch"
		| "pg"
		| "clickhouse"
		| "grafana"
		| "influxdb"
		| "mysql"
		| "kafka-connect"
		| "m3"
		| "redis"
		| "cassandra";
	tagline?: string;
	title?: string;
	subtitle?: SimplePortableText;
	steps?: Step[];
	resources?: SimplePortableText;
	toolsTitle?: string;
	tools?: Logo[];
}

export function ServiceDiagram({
	variant = "kafka",
	tagline,
	title,
	subtitle,
	steps,
	resources,
	toolsTitle,
	tools,
}: ServiceDiagramProps) {
	const [activeStep, setActiveStep] = React.useState("step1");
	const [isModalOpen, setModalOpen] = React.useState(false);

	const isMobile = useMobile();

	const handleClick = (name: string) => {
		setActiveStep(name);
		if (isMobile) {
			setModalOpen(true);
		}
	};

	const renderSvg = (variant: string) => {
		switch (variant) {
			case "kafka":
				return <KafkaSvg onStepChange={handleClick} currentStep={activeStep} />;
			case "kafka-ecosystem":
				return (
					<KafkaEcosystemSvg
						onStepChange={handleClick}
						currentStep={activeStep}
					/>
				);
			case "flink":
				return <FlinkSvg onStepChange={handleClick} currentStep={activeStep} />;
			case "pg":
				return <PgSvg onStepChange={handleClick} currentStep={activeStep} />;
			case "opensearch":
				return (
					<OpensearchSvg onStepChange={handleClick} currentStep={activeStep} />
				);
			case "clickhouse":
				return (
					<ClickhouseSvg onStepChange={handleClick} currentStep={activeStep} />
				);
			case "grafana":
				return (
					<GrafanaSvg onStepChange={handleClick} currentStep={activeStep} />
				);
			case "influxdb":
				return <InfluxdbSvg />;
			case "mysql":
				return <MysqlSvg />;
			case "kafka-connect":
				return <KafkaConnectSvg />;
			case "m3":
				return <M3Svg />;
			default:
				return null;
		}
	};

	const interactiveSvg = (variant: string) => {
		switch (variant) {
			case "kafka":
			case "kafka-ecosystem":
			case "flink":
			case "pg":
			case "opensearch":
			case "clickhouse":
			case "grafana":
				return true;
			default:
				return false;
		}
	};

	const noSvg = (variant: string) => {
		switch (variant) {
			case "redis":
			case "cassandra":
				return true;
			default:
				return false;
		}
	};

	return (
		<>
			{tagline || title || subtitle ? (
				<div className="mx-auto max-w-content text-center">
					{tagline ? (
						<Paragraph size="overline" className="mb-5" color="tagline">
							{tagline}
						</Paragraph>
					) : null}
					{title ? <H2 className="mb-5">{title}</H2> : null}
					{subtitle ? <BlockContent value={subtitle} /> : null}
				</div>
			) : null}
			<div className="grid gap-layout2 pt-layout4 md:grid-cols-2 lg:grid-cols-[65%_33%]">
				{!noSvg(variant) ? (
					<div className="border-stroke bg-primary order-1 row-span-2 flex items-center justify-center rounded-lg border py-layout2 md:py-0">
						{renderSvg(variant)}
					</div>
				) : null}
				{steps ? (
					<Steps
						className={clsx("order-3 md:order-2", {
							"row-span-2": noSvg(variant),
						})}
						stepItems={steps}
						currentStep={activeStep}
						stepState={setActiveStep}
						isModalOpen={isModalOpen}
						closeModal={() => setModalOpen(false)}
						isInteractiveSvg={interactiveSvg(variant)}
						minHeight={
							interactiveSvg(variant) ? "md:min-h-[710px]" : "md:min-h-[100%]"
						}
					/>
				) : null}
				{tools ? (
					<Tools
						className="border-stroke bg-primary order-2 rounded-lg border p-layout2 md:order-3"
						title={toolsTitle}
						logoItems={tools}
					/>
				) : null}
				{noSvg(variant) && resources ? (
					<div className="border-stroke bg-primary order-4 rounded-lg border p-layout2">
						<BlockContent value={resources.content} />
					</div>
				) : null}
			</div>
		</>
	);
}
