import { IconList as IconListComponent } from "../icon-list";
import type { IconItemType } from "../icon-list";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/utils/images";
import { asLink, asText } from "~/utils/sanity-helpers";
import type {
	IconList as IconListType,
	IconItem,
	SanityReference,
} from "~/types/sanity-schema";
import { Icon } from "~/components/ui/icons";

function isIconItems(
	icons: IconItem[] | SanityReference[] | undefined
): icons is Array<IconItem> {
	return Array.isArray(icons) && icons[0] && "iconImage" in icons[0];
}

export function IconList({
	title,
	subtitle,
	tagline,
	icons,
	variant,
	itemsPerRow,
	headerPosition,
	primaryCTA,
	secondaryCTA,
	iconSize = 24,
}: IconListType) {
	const iconItems: IconItemType[] = isIconItems(icons)
		? icons.map((item) => ({
				title: item?.title,
				subtitle: item?.subtitle,
				imageProps: getImageProps(
					getImageBuilder(item?.iconImage, {
						alt: getImageAltProp(item?.iconImage),
					})?.width(iconSize)
				),
				cta: {
					title: item?.cta?.title,
					url: asLink(item?.cta),
					iconLeft: item.cta?.isPdf ? (
						<Icon name="file" color="primary" />
					) : null,
				},
			}))
		: [];

	// Fallback value from variant that is now replaced with headerPosition to avoid layout breaking in existing pages without these values
	const headerPositionFromVariant =
		variant === "icon-left" ? "default" : "left";
	const itemsPerRowFromVariant = variant === "icon-left" ? 3 : 2;

	return (
		<IconListComponent
			headerPosition={
				headerPosition ? headerPosition : headerPositionFromVariant
			}
			itemsPerRow={itemsPerRow ? itemsPerRow : itemsPerRowFromVariant}
			iconItems={iconItems}
			title={asText(title)}
			subtitle={subtitle}
			tagline={tagline}
			primaryCTA={{
				title: primaryCTA?.title,
				url: asLink(primaryCTA),
				trackingPosition: "icon",
				iconLeft: primaryCTA?.isPdf ? <Icon name="file" /> : null,
			}}
			secondaryCTA={{
				title: secondaryCTA?.title,
				url: asLink(secondaryCTA),
				trackingPosition: "icon",
				iconLeft: secondaryCTA?.isPdf ? <Icon name="file" /> : null,
			}}
			iconSize={iconSize}
		/>
	);
}
