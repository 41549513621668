import { TextMedia as TextImageComponent } from "../text-media";
import { asLink } from "~/utils/sanity-helpers";
import {
	getImageBuilder,
	getImageProps,
	getImageAltProp,
} from "~/utils/images";
import type { TextImage as TextImageType } from "~/types/sanity-schema";
import { Icon } from "~/components/ui/icons";

export function TextImage({
	image,
	mobileImage,
	narrowImage: isNarrowImage = false,
	mediaWidth = "default",
	mobileImageSetting = "default",
	tagline,
	title,
	primaryCTA,
	secondaryCTA,
	tertiaryCTA,
	text,
	variant,
	imagePosition,
}: TextImageType) {
	const imgProps = getImageProps(
		getImageBuilder(image, { alt: getImageAltProp(image) }),
		{
			widths: [400, 600, 800, 1000, 1300],
			sizes: ["(min-width:1024px) 75vw", "100vw"],
		}
	);

	const mobileImgProps = getImageProps(
		getImageBuilder(mobileImage, { alt: getImageAltProp(mobileImage) }),
		{
			widths: [400, 600, 800],
		}
	);

	return (
		<TextImageComponent
			media="image"
			variant={variant}
			tagline={tagline}
			title={title}
			mediaWidth={isNarrowImage ? "sm" : mediaWidth}
			image={imgProps}
			imagePosition={imagePosition}
			mobileImageSetting={mobileImageSetting}
			mobileImage={mobileImgProps}
			text={text}
			primaryCTA={{
				title: primaryCTA?.title,
				caption: primaryCTA?.caption,
				url: asLink(primaryCTA),
				trackingPosition: "text image",
				iconLeft: primaryCTA?.isPdf ? <Icon name="file" /> : null,
			}}
			secondaryCTA={{
				title: secondaryCTA?.title,
				caption: secondaryCTA?.caption,
				url: asLink(secondaryCTA),
				trackingPosition: "text image",
				iconLeft: secondaryCTA?.isPdf ? <Icon name="file" /> : null,
			}}
			tertiaryCTA={{
				title: tertiaryCTA?.title,
				caption: tertiaryCTA?.caption,
				url: asLink(tertiaryCTA),
				trackingPosition: "text image",
				iconLeft: tertiaryCTA?.isPdf ? (
					<Icon name="file" color="primary" />
				) : null,
			}}
		/>
	);
}
