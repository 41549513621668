import clsx from "clsx";
import React from "react";
import { BlockContent } from "~/components/block-content";
import { Icon } from "~/components/ui/icons";
import { RemoveScroll } from "react-remove-scroll";
import type { SimplePortableText } from "~/types/sanity-schema";
import { spTrackWebInteraction } from "~/utils/tracking";

export type Step = {
	content?: SimplePortableText;
};

interface Props {
	className?: string;
	stepItems: Step[];
	currentStep: NonNullable<string>;
	stepState: React.Dispatch<React.SetStateAction<string>>;
	isModalOpen: boolean;
	closeModal: () => void;
	isInteractiveSvg: boolean;
	minHeight?: string;
}

export function Steps({
	className,
	stepItems,
	currentStep,
	stepState,
	isModalOpen,
	closeModal,
	isInteractiveSvg = false,
	minHeight = "md:min-h-[700px]",
}: Props) {
	const stepNumber = parseInt(currentStep.replace(/^\D+/g, ""));

	const maxStep = stepItems.length;

	const handleClick = (direction: string) => {
		if (direction === "back" && stepNumber > 1) {
			stepNumber === 1
				? stepState(`step${maxStep}`)
				: stepState(`step${stepNumber - 1}`);

			spTrackWebInteraction({
				object: "diagram navigation",
				action: "previous",
				value: `step ${stepNumber - 1}`,
			});
		} else if (direction === "next" && stepNumber < maxStep) {
			stepNumber === maxStep
				? stepState("step1")
				: stepState(`step${stepNumber + 1}`);

			spTrackWebInteraction({
				object: "diagram navigation",
				action: "next",
				value: `step ${stepNumber + 1}`,
			});
		}
	};

	const buttonStyling =
		"rounded-sm border p-4 bg-button-secondary text-button-secondary border-button-secondary disabled:cursor-not-allowed";

	return (
		<RemoveScroll enabled={isModalOpen} className={className}>
			{stepItems.length > 0 ? (
				<div
					className={clsx(
						`${minHeight} border-stroke bg-primary rounded-lg border md:relative md:flex md:flex-col`,
						{
							hidden: !isModalOpen && isInteractiveSvg,
							"fixed inset-0 z-50 block overflow-y-auto overscroll-none":
								isModalOpen,
						}
					)}
				>
					{isInteractiveSvg ? (
						<div className="border-stroke flex h-[70px] justify-end border-b pr-5 md:hidden">
							<button onClick={closeModal}>
								<span className="sr-only">Close menu</span>
								<Icon name="close-button" color="primary" />
							</button>
						</div>
					) : null}
					{stepItems.map((step, index) => (
						<article
							key={index}
							className={clsx("p-layout2", {
								hidden: `step${index + 1}` !== currentStep,
								block: `step${index + 1}` === currentStep,
							})}
						>
							{step.content ? (
								<BlockContent
									value={step.content}
									headingClassName="first-of-type:!mt-0"
								/>
							) : null}
						</article>
					))}
					{stepItems.length > 1 ? (
						<nav className="mt-auto hidden gap-5 p-layout2 md:flex">
							<button
								className={buttonStyling}
								title="Previous step"
								onClick={() => handleClick("back")}
								disabled={stepNumber === 1}
							>
								<Icon name="arrow-left" />
							</button>
							<button
								className={buttonStyling}
								title="Next step"
								onClick={() => handleClick("next")}
								disabled={stepNumber === maxStep}
							>
								<Icon name="arrow-right" />
							</button>
						</nav>
					) : null}
				</div>
			) : null}
		</RemoveScroll>
	);
}
