import type { RelatedBlog as RelatedBlogType } from "~/types/sanity-schema";
import type { AdditionalSectionData } from "~/components/renderSections";
import { H2 } from "~/components/ui/typography";
import { BlogGrid } from "~/components/blog/blog-grid";
import { getImageBuilder, getImageAltProp } from "~/utils/images";
import type { Post } from "~/components/blog/blog-card";
import { asText } from "~/utils/sanity-helpers";

type Props = RelatedBlogType & {
	relatedPostData: AdditionalSectionData["relatedPostData"];
};

export function RelatedBlogs({ title, relatedPostData }: Props) {
	if (!relatedPostData || relatedPostData.length === 0) {
		return null;
	}

	const posts: Post[] = relatedPostData.map((item) => {
		const authors = item.authors
			? item.authors.map((author) => ({
					name: asText(author?.name),
					shortName: asText(author?.shortName),
					imageBuilder: getImageBuilder(author?.image, {
						alt: getImageAltProp(author?.image, {
							fallback: asText(author?.name),
						}),
					})
						?.width(50)
						.height(50),
				}))
			: [];

		return {
			id: item._id,
			title: asText(item.title),
			date: item.publishedAt,
			coverImgBuilder: getImageBuilder(item.mainImage, {
				alt: getImageAltProp(item.mainImage, {
					fallback: `${asText(item.title)} illustration`,
				}),
			}),
			slug: item.slug.current,
			description: item.subtitle,
			authors,
		};
	});

	return (
		<div>
			<div className="mx-auto mb-7 max-w-content text-center">
				{title ? <H2 className="mb-5">{title}</H2> : null}
			</div>
			<BlogGrid posts={posts} lang={"en"} />
		</div>
	);
}
