import type { GroupedPlansData } from "~/types/product";
import { Table } from "~/components/ui/table";
import { notEmpty } from "~/utils/misc";
import {
	getDisabledColumns,
	formatCurrency,
	getSignupUrl,
} from "~/utils/pricing";
import clsx from "clsx";
import { useSharedContent } from "~/hooks/localization";
import { Dictionary } from "~/utils/language";
import { ButtonLink } from "~/components/ui/button";

type Props = {
	planGroup: GroupedPlansData["group"];
	service: string;
};

type TableHeading = {
	key: string;
	label: string;
	width?: string;
	align?: string;
};

// To align colmns of pricing and addon tables
const HOURLY_PRICE_COL_WIDTH = "140px";
const MONTHLY_PRICE_COL_WIDTH = "195px";

export function PricingTable({ planGroup, service }: Props) {
	const { t } = useSharedContent(Dictionary.PRICING);

	const disabledColumns = getDisabledColumns(planGroup);

	const tableHeadings: Array<TableHeading> = [
		{ key: "planTier", label: "Plan tier", width: "180px" },
		!disabledColumns.nodeCount
			? {
					key: "dedicatedVMs",
					label: "Dedicated VMs",
					align: "text-right",
				}
			: null,
		!disabledColumns.cpuCount
			? { key: "cpuPerVM", label: "CPU per VM", align: "text-right" }
			: null,
		!disabledColumns.memoryGb
			? { key: "ramPerVM", label: "RAM per VM", align: "text-right" }
			: null,
		!disabledColumns.shardCount
			? { key: "shards", label: "Shards", align: "text-right" }
			: null,
		!disabledColumns.diskSpaceGb
			? {
					key: "totalStorage",
					label: "Total storage",
					align: "text-right",
				}
			: null,
		{
			key: "hourlyPrice",
			label: "Hourly price",
			width: HOURLY_PRICE_COL_WIDTH,
			align: "text-right",
		},
		{
			key: "estMonthlyPrice",
			label: "Est. monthly price",
			width: MONTHLY_PRICE_COL_WIDTH,
			align: "text-right",
		},
	].filter(notEmpty);

	function renderPlanRows(group: GroupedPlansData["group"]) {
		const plans = group.plans || [];

		const planRows = plans.map((plan) => (
			<Table.Row key={plan.name}>
				<Table.Cell className="bg-primary sticky left-0 md:bg-transparent">
					{plan.name}
				</Table.Cell>
				{!disabledColumns.nodeCount && (
					<Table.Cell className="text-right">{plan.nodeCount}</Table.Cell>
				)}
				{!disabledColumns.cpuCount && (
					<Table.Cell className="text-right">{plan.cpuCount}</Table.Cell>
				)}
				{!disabledColumns.memoryGb && (
					<Table.Cell className="text-right">{plan.memoryGb}GB</Table.Cell>
				)}
				{!disabledColumns.shardCount && (
					<Table.Cell className="text-right">{plan.shardCount}</Table.Cell>
				)}
				{!disabledColumns.diskSpaceGb && (
					<Table.Cell className="text-right">{plan.diskSpaceGb}GB</Table.Cell>
				)}
				<Table.Cell className="text-right">
					{formatCurrency({ value: plan.pricePerHourUsd })}
				</Table.Cell>
				<Table.Cell className="text-right">
					{formatCurrency({
						value: plan.pricePerMonthUsd,
						fractionDigits: 0,
					})}
				</Table.Cell>
			</Table.Row>
		));

		return planRows;
	}

	return (
		<div className="overflow-x-auto">
			<Table>
				<Table.Head>
					{tableHeadings.map(({ key, label, width, align }, index) => {
						const firstCell = index === 0;

						return (
							<Table.Cell
								className={clsx(align, {
									"bg-primary sticky left-0 md:bg-transparent": firstCell,
								})}
								width={width ? width : "auto"}
								key={key}
							>
								{t(key, label)}
							</Table.Cell>
						);
					})}
				</Table.Head>
				<Table.Body>{renderPlanRows(planGroup)}</Table.Body>
			</Table>
			<div className="pt-layout3 lg:text-right">
				<ButtonLink
					variant="primary"
					to={getSignupUrl({
						service: service,
						plan: planGroup.name,
					}).concat(`&utm_campaign=website_pricing&utm_content=button`)}
					trackingPosition="modal"
				>
					{t("ctaSignup")}
				</ButtonLink>
			</div>
		</div>
	);
}
