import React from "react";
import groupBy from "lodash/groupBy";
import { Modal } from "~/components/ui/modal";
import clsx from "clsx";
import { Paragraph } from "~/components/ui/typography";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import { Icon } from "~/components/ui/icons";
import { useSharedContent } from "~/hooks/localization";
import { Dictionary, getLocalePath } from "~/utils/language";
import { Link } from "~/components/ui/link";
import { useSearchParams } from "@remix-run/react";

export type ProductItemType = {
	externalId: string;
	title: string;
	iconProps: ImageProps;
	group: string;
	isSelected?: boolean;
	locale: string;
	itemUrl: string;
};

type ProductItemProps = {
	item: ProductItemType;
	onClick?: () => void;
};

function ProductItem({ item, onClick }: ProductItemProps) {
	const { isSelected = false, externalId, locale, itemUrl } = item;

	const [searchParams] = useSearchParams();

	// set correct product id
	searchParams.set("product", externalId);

	return (
		<Link
			onClick={onClick}
			preventScrollReset={true}
			to={getLocalePath(`${itemUrl}?${searchParams.toString()}`, locale)}
		>
			<div
				className={clsx(
					"flex h-full flex-col gap-3 rounded border px-5 py-5 text-left sm:h-[80px] sm:flex-row sm:items-center sm:py-3",
					{ "border-stroke": !isSelected },
					{ "border-orange-60": isSelected }
				)}
			>
				<Image
					loading="eager"
					className="h-[32px] w-[32px] rounded-full"
					{...item.iconProps}
				/>
				<Paragraph size="body-xsmall">{item.title}</Paragraph>
			</div>
		</Link>
	);
}

type ProductGroupProps = {
	items: Array<ProductItemType>;
	group: string;
	onProductSelect: (id: string) => void;
	variant: "default" | "small";
};

function ProductGroup({
	items,
	group,
	onProductSelect,
	variant = "default",
}: ProductGroupProps) {
	return (
		<div>
			<Paragraph className="mb-3" size="overline" color="tagline">
				{group}
			</Paragraph>
			<div
				className={clsx(
					"grid grid-cols-2 gap-3 md:grid-cols-3",
					{ "xl:grid-cols-6": variant === "default" },
					{ "xl:grid-cols-4": variant === "small" }
				)}
			>
				{items.map((item) => (
					<ProductItem
						key={item.externalId}
						item={item}
						onClick={() => onProductSelect(item.externalId)}
					/>
				))}
			</div>
		</div>
	);
}

type ServiceListProps = {
	productsData: Array<ProductItemType>;
	onChange: (productId: string) => void;
	value: string;
	variant?: ProductGroupProps["variant"];
};

const GROUP_ORDER: Record<string, number> = {
	stream: 1,
	store: 2,
	serve: 3,
};

function sortGroup(a: string, b: string) {
	const orderA = GROUP_ORDER[a] ?? 0;
	const orderB = GROUP_ORDER[b] ?? 0;

	return orderA - orderB;
}

export function ServiceSelect({
	productsData,
	onChange,
	value,
	variant = "default",
}: ServiceListProps) {
	const { t } = useSharedContent(Dictionary.PRICING);

	const productsGroupByService = groupBy(
		productsData.map((product) => ({
			...product,
			isSelected: product.externalId === value,
		})),
		"group"
	);

	function handleProductChange(id: string) {
		onChange(id);
	}

	return (
		<div className="flex flex-col gap-5">
			{Object.keys(productsGroupByService)
				.sort(sortGroup)
				.map((group) => {
					const products = productsGroupByService[group];

					return (
						<ProductGroup
							variant={variant}
							onProductSelect={handleProductChange}
							key={group}
							group={t(group)}
							items={products}
						/>
					);
				})}
		</div>
	);
}

export function ServiceSelectMobile({
	productsData,
	onChange,
	value,
}: ServiceListProps) {
	const selectedProduct = React.useMemo(() => {
		return productsData.find((product) => product.externalId === value);
	}, [productsData, value]);

	const [showDialog, setShowDialog] = React.useState(false);
	const closeDialog = () => setShowDialog(false);
	const openDialog = () => setShowDialog(true);

	if (!selectedProduct) {
		return null;
	}

	function handleProductChange(id: string) {
		closeDialog();

		onChange(id);
	}

	return (
		<>
			<button onClick={openDialog} className="w-full">
				<div
					className={clsx(
						"border-stroke bg-primary flex w-full items-center justify-between rounded border px-5 py-3 text-left"
					)}
				>
					<div className="flex items-center gap-3">
						<Image
							loading="eager"
							className="h-[32px] w-[32px] rounded-full"
							{...selectedProduct.iconProps}
						/>
						<Paragraph size="body-small">{selectedProduct.title}</Paragraph>
					</div>
					<Icon name="chevron-down" color="secondary" className="h-5 w-5" />
				</div>
			</button>
			<Modal ariaLabel="Service List" isOpen={showDialog} onClose={closeDialog}>
				<Modal.Header title="Select a service" />
				<Modal.Content>
					<ServiceSelect
						productsData={productsData}
						onChange={handleProductChange}
						value={value}
					/>
				</Modal.Content>
			</Modal>
		</>
	);
}
