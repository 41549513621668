import { useMemo } from "react";
import type { ImgPropsOptions } from "~/utils/images";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/utils/images";
import { ImageSectionComponent as ImageComponent } from "../image";
import type { CoverImage as CoverImageType } from "~/types/sanity-schema";

export function Image({
	captionImage,
	isCarousel,
	carouselImages,
}: CoverImageType) {
	const imgOptions = (): ImgPropsOptions => {
		return {
			widths: [400, 600, 800, 1000, 1300],
			sizes: ["(min-width:1024px) 90vw", "100vw"],
		};
	};

	const content = useMemo(() => {
		if (isCarousel) {
			return carouselImages
				? carouselImages.map((captionImage) => ({
						...getImageProps(
							getImageBuilder(captionImage?.image, {
								alt: getImageAltProp(captionImage?.image),
							}),
							imgOptions()
						),
						caption: captionImage?.caption,
					}))
				: [];
		}
		return {
			...getImageProps(
				getImageBuilder(captionImage?.image, {
					alt: getImageAltProp(captionImage?.image),
				}),
				imgOptions()
			),
			caption: captionImage?.caption,
		};
	}, [captionImage, carouselImages, isCarousel]);

	return <ImageComponent content={content} />;
}
