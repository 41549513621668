import { asText } from "~/utils/sanity-helpers";
import type { SocialMediaShare as SocialMediaShareType } from "~/types/sanity-schema";
import { SharingLinks } from "~/components/sharing-links";

export function SocialMediaShare({
	tagline,
	title,
	subtitle,
	shareUrl,
	shareTitle,
	disableLinkedinShare,
	disableFacebookShare,
	disableTwitterShare,
	disableEmailShare,
}: SocialMediaShareType) {
	return (
		<SharingLinks
			tagline={tagline}
			title={asText(title)}
			subtitle={subtitle}
			variant="button"
			shareTitle={asText(shareTitle)}
			currentUrl={asText(shareUrl)}
			disableLinkedinShare={disableLinkedinShare}
			disableFacebookShare={disableFacebookShare}
			disableTwitterShare={disableTwitterShare}
			disableEmailShare={disableEmailShare}
		/>
	);
}
