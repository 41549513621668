import { H2, Paragraph } from "~/components/ui/typography";
import { BlockContent } from "~/components/block-content";
import type { SimplePortableText } from "~/types/sanity-schema";
import { Icon } from "./ui/icons";
import { ArrowLink } from "~/components/ui/arrow-button";
import type { Cta } from "~/types";

export type Location = {
	title?: string;
	address?: string;
	link?: Cta;
	icon?: "globe" | "home";
};

export function LocationItem({
	title,
	address,
	link,
	icon = "globe",
}: Location) {
	return (
		<address className="flex gap-3 not-italic">
			<div className="w-[24px]">
				<Icon name={icon} color="primary" width="24" height="24" />
			</div>
			<div>
				{title ? (
					<Paragraph
						size="body-large"
						fontWeight="font-semibold"
						className="mb-3"
					>
						{title}
					</Paragraph>
				) : null}
				{address ? (
					<Paragraph size="body-default" color="secondary" className="mb-3">
						{address}
					</Paragraph>
				) : null}
				{link && link.url ? (
					<ArrowLink to={link.url} className="mt-5">
						{link.title}
					</ArrowLink>
				) : null}
			</div>
		</address>
	);
}

interface LocationListProps {
	title?: string;
	subtitle?: SimplePortableText;
	locationItems: Location[];
}

export function LocationList({
	title,
	subtitle,
	locationItems,
}: LocationListProps) {
	return (
		<>
			{title || subtitle ? (
				<div className="mb-layout3 max-w-content">
					{title ? <H2>{title}</H2> : null}
					{subtitle ? (
						<div className="mt-5">
							<BlockContent value={subtitle} />
						</div>
					) : null}
				</div>
			) : null}
			{locationItems.length > 0 ? (
				<div className="flex flex-col gap-layout2 sm:grid sm:grid-cols-2 sm:gap-layout4 lg:grid-cols-4">
					{locationItems.map((location, index) => (
						<LocationItem
							key={index}
							title={location.title}
							address={location.address}
							link={location.link}
							icon={location.icon}
						/>
					))}
				</div>
			) : null}
		</>
	);
}
